import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import store from '../store';
import AuthService from '@/services/auth-service';

const middlewarePipeline = (context, middleware, index) => {
    const nextMiddleware = middleware[index]
    if(!nextMiddleware){
        return context.next
    }
    return () => {
        const nextPipeline = middlewarePipeline(
            context, middleware, index + 1
        )
        nextMiddleware({ ...context, nextMiddleware: nextPipeline })
    }
}


Vue.use(Router);

const router = new Router({
  linkActiveClass: 'active',
  mode: 'history',
  routes: routes(),
  scrollBehavior: () => ({ y: 0 }),
});

router.beforeEach(async (to, from, next) => {
  if (from.name === null) {
   await AuthService.loginByAccessToken().catch(() => {});
  }

  document.title = to.meta.title ? `${to.meta.title} | FullHub` : 'FullHub';
  if (to.matched.some((record) => record.meta.middleware)) {
    const { middleware } = to.matched[0].meta;

    const context = {
      to,
      from,
      next,
      store,
    };

    return middleware[0]({
      ...context,
      nextMiddleware: middlewarePipeline(context, middleware, 1),
    });
  }

  return next();
});

export default router;
