<template>
  <router-view />
</template>

<script>
import 'vue2-datepicker/locale/ru';
export default {
  name: 'App',
};
</script>

<style lang="scss">
@import 'assets/scss/style';
</style>
