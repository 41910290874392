import Vue from 'vue';

export default {
  set(state, [variable, value]) {
    state[variable] = value;
  },
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : 'responsive';
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : 'responsive';
  },
  newDraft(state, payload) {
    console.log(payload);
    Vue.set(state.drafts[payload.type], payload.draft.uuid, payload.draft);
  },
  removeDraft(state, payload) {
    Vue.delete(state.drafts[payload.type], payload.uuid);
  }
}
