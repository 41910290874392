import { v4 } from 'uuid';
import { get } from 'lodash';

export const createDraftStore = ({commit}, draft) => {
  const obj = {...draft, uuid: v4()};
  commit('newDraft', {type: 'store', draft: obj});
  return obj.uuid;
}

export const purgeDraftStore = ({commit, state}, uuid) => {
  if (!get(state.drafts, ['store', uuid])) {
    return
  }
  commit('removeDraft', {type: 'store', uuid});
}
