import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import { camelizeResponse, transformRequest } from '@/utils/api-helpers'

const config = {
  baseURL: process.env.VUE_APP_DOMAIN,
  // withCredentials: true, // Check cross-site Access-Control
};

export const customAxios = axios.create(config);

customAxios.interceptors.request.use(
  (requestConfig) => {
    const request = { ...requestConfig };

    if (store.getters.isAuthorized) {
      request.headers.Authorization = `Bearer ${store.state.auth.accessToken}`;
      request.headers['X-Locale'] = store.getters.locale;
    }

    return transformRequest(request);
  },
  (error) => Promise.reject(error),
);

// Add a response interceptor
customAxios.interceptors.response.use(
  (response) => camelizeResponse(response),
  (error) => {
    error.response = camelizeResponse(error.response)
    return Promise.reject(error)
  }
);

// eslint-disable-next-line no-shadow
Plugin.install = function install(Vue/* , options */) {
  // eslint-disable-next-line no-param-reassign
  Vue.axios = customAxios;
  window.axios = customAxios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return customAxios;
      },
    },
    $axios: {
      get() {
        return customAxios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
