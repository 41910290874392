import Vue from 'vue'
import Vuex from 'vuex'
import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

const state = {
  auth: {
    accessToken: null,
    user: {
      email: null,
      id: null,
      roles: [],
    },
  },
  drafts: {
    store: {}
  },
  authorized: false,
  sidebarMinimize: false,
  sidebarShow: 'responsive',
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})
