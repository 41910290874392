import authorized from './middlewares/authorized';
import serviceAdmin from './middlewares/service-admin';

export default function () {
  return [
    {
      children:[
        {
          path: '/',
          name: 'admin.index',
          meta: {
            label: 'Главная',
            title: 'Главная',
            middleware: [
              serviceAdmin,
            ]
          },
          component: () => import('../views/admin/index'),
        },
        {
          path: '/transits',
          name: 'admin.transits.index',
          meta: {
            label: 'Транзиты',
            title: 'Транзиты',
            middleware: [
              serviceAdmin,
            ]
          },
          component: () => import('../views/admin/transits/list/index')
        },
        {
          path: '/transits/:id(\\d+)',
          name: 'admin.transits.show',
          meta: {
            label: 'Транзит',
            title: 'Транзит',
            middleware: [
              serviceAdmin,
            ]
          },
          component: () => import('../views/admin/transits/show/index')
        },
        {
          children: [
            {
              path: 'customerorders/',
              name: 'admin.documents.customer_orders.index',
              meta: {
                label: 'Отгрузки',
                title: 'Отгрузки',
              },
              props: {documentType: 'Document::CustomerOrder'},
              component: () => import('../views/admin/documents/list'),
            },
            {
              path: 'dispatches/',
              name: 'admin.documents.dispatches.index',
              meta: {
                label: 'Отгрузки',
                title: 'Отгрузки',
              },
              props: {documentType: 'Document::Dispatch'},
              component: () => import('../views/admin/documents/list'),
            },
            {
              path: 'returns/',
              name: 'admin.documents.returns.index',
              meta: {
                label: 'Возвраты',
                title: 'Возвраты',
              },
              props: {documentType: 'Document::Return'},
              component: () => import('../views/admin/documents/list'),
            },
            {
              path: 'movements/',
              name: 'admin.documents.movements.index',
              meta: {
                label: 'Перемещения',
                title: 'Перемещения',
              },
              props: {documentType: 'Document::Movement'},
              component: () => import('../views/admin/documents/list'),
            },
            {
              path: 'internal_orders/',
              name: 'admin.documents.internal_orders.index',
              meta: {
                label: 'Внутренние заказы',
                title: 'Внутренние заказы',
              },
              props: {documentType: 'Document::InternalOrder'},
              component: () => import('../views/admin/documents/list'),
            },
            {
              path: 'enters/',
              name: 'admin.documents.enters.index',
              meta: {
                label: 'Оприходования',
                title: 'Оприходования',
              },
              props: {documentType: 'Document::Enter'},
              component: () => import('../views/admin/documents/list'),
            },
            {
              path: ':id/',
              name: 'admin.documents.info',
              meta: {
                label: 'Документ',
                title: 'Документ',
              },
              component: () => import('../views/admin/documents/info'),
            }
          ],
          meta: {
            middleware: [
              serviceAdmin,
            ]
          },
          path: 'documents/',
          component: () => import('../views/admin/documents/index'),
        },
        {
          children: [
            {
              path: '',
              name: 'admin.warehouses.index',
              meta: {
                label: 'Фулфилменты',
                title: 'Фулфилменты',
              },
              component: () => import('../views/admin/warehouses/list'),
            },
            {
              path: 'create/one_s',
              name: 'admin.warehouses.create.one_s',
              meta: {
                label: 'Добавление 1С ФФ',
                title: 'Добавление 1С ФФ',
              },
              component: () => import('../views/admin/warehouses/create/one_s'),
            },
            {
              path: ':id/',
              name: 'admin.warehouses.show',
              meta: {
                label: 'Настройки ФФ',
                title: 'Настройки ФФ'
              },
              component: () => import('../views/admin/warehouses/show'),
            }
          ],
          meta: {
            middleware: [
              serviceAdmin,
            ]
          },
          path: 'warehouses/',
          component: () => import('../views/admin/warehouses/index'),
        },
        {
          meta: {
            label: 'Настройки 1С',
            title: 'Настройки 1С',
            middleware: [
              serviceAdmin,
            ]
          },
          name: 'admin.one_s_setting.show',
          path: 'one_s_setting/:id/',
          component: () => import('../views/admin/one_s/show/index'),
        },
        {
          children: [
            {
              path: '',
              name: 'admin.stores.index',
              meta: {
                label: 'Склады',
                title: 'Склады'
              },
              component: () => import('../views/admin/stores/list')
            },
            {
              path: 'new/',
              name: 'admin.stores.create',
              meta: {
                label: 'Создание склада',
                title: 'Создание склада'
              },
              component: () => import('../views/admin/stores/create')
            },
            {
              path: ':id/',
              name: 'admin.stores.show',
              meta: {
                label: 'Редактирование склада',
                title: 'Редактирование склада'
              },
              component: () => import('../views/admin/stores/show')
            },
          ],
          meta: {
            middleware: [
              serviceAdmin,
            ]
          },
          path: 'stores/',
          component: () => import('../views/admin/stores/index'),
        },
        {
          meta: {
            label: 'Настройка МойСклад',
            title: 'Настройка МойСклад',
            middleware: [
              serviceAdmin,
            ]
          },
          path: 'moysklad/',
          name: 'admin.moysklad.setup',
          component: () => import('../views/admin/moysklad/create'),
        },
        {
          meta: {
            label: 'Отчет',
            title: 'Отчет о расхождениях',
          },
          path: 'reports/',
          name: 'admin.reports.index',
          component: () => import('../views/admin/report/index'),
        },
      ],
      meta: {
        middleware: [
          authorized,
        ]
      },
      component: () => import('../containers/main-container'),
      path: '/admin',
    },
    {
      children: [
        // Восстановление пароля
        {
          component: () => import('../views/Recover.vue'),
          meta: {
          },
          name: 'recover',
          path: 'recover',
        },
        // Вход в личный кабинет
        {
          component: () => import('../views/Login.vue'),
          meta: {
          },
          name: 'login',
          path: 'login',
        },
      ],
      component: () => import('../views/Index.vue'),
      path: '/',
    },
    {
       component: () => import('../views/admin/Logout'),
       meta: {
         middleware: [
           authorized,
         ],
       },
       name: 'admin.logout',
       path: '/admin/logout',
     }
  ]
}
