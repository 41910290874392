import store from '../store';
import { customAxios } from '../plugins/axios';

export class NoServiceRolesError extends Error {}

export const PERMISSION_ADMIN = 'ROLE_SERVICE_ADMIN';

const urls = {
  authServiceLoginUrl: `${process.env.VUE_APP_AUTH_SERVICE_DOMAIN}/api/login`,
  authServiveRecoveryUrl: `${process.env.VUE_APP_AUTH_SERVICE_DOMAIN}/api/user/recovery_password/send_code`
}

const APP_SERVICE_URL = process.env.VUE_APP_SERVICE_URL || 'https://test-fh.inlb.ru';

function getTokenData(accessToken) {
  const payload = accessToken.split('.')[1];

  return JSON.parse(atob(payload));
}

async function setAuthData(accessToken) {
  const { email, id, services } = getTokenData(accessToken);
  console.log(email, id, services);

  // eslint-disable-next-line max-len
  const service = services.find(({ serviceUrl }) => APP_SERVICE_URL === serviceUrl);

  if (!service) {
    throw new NoServiceRolesError();
  }

  const auth = {
    accessToken,
    user: {
      email,
      id,
      roles: service.roles
    }
  };

  store.commit('set', ['auth', auth]);

  localStorage.setItem('accessToken', accessToken);
}

export default class {
  static getBearer() {
    return `Bearer ${store.state.auth.accessToken}`;
  }

  static isAccessTokenExpired(accessToken) {
    const tokenData = getTokenData(accessToken);
    const accessTokenExp = tokenData.exp - 10;

    return accessTokenExp <= Math.floor(new Date().getTime() / 1000);
  }

  static async loginByAccessToken() {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      if (this.isAccessTokenExpired(accessToken)) {
        this.logout();
      } else {
        await setAuthData(accessToken);
      }
    }
  }

  static async loginByEmailAndPassword({ email, password }) {
    const data = {
      email,
      password,
    };

    const requestConfig = {};

    if (process.env.VUE_APP_X_ORIGIN_DEV) {
      requestConfig.headers = {
        'X-Origin-Dev': process.env.VUE_APP_X_ORIGIN_DEV,
      };
    }

    const response = await customAxios.post(urls.authServiceLoginUrl, data, requestConfig);

    console.log(response.data);

    await setAuthData(response.data.token);
  }

  static async restorePassword({ email }) {
    const requestConfig = {};

    if (process.env.VUE_APP_X_ORIGIN_DEV) {
      requestConfig.headers = {
        'X-Origin-Dev': process.env.VUE_APP_X_ORIGIN_DEV,
      };
    }

    await customAxios.post(urls.authServiveRecoveryUrl, { email }, requestConfig);
  }

  static logout() {
    this.resetAuthData();
  }

  static resetAuthData() {
    const auth = {
      accessToken: null,
      user: {
        email: null,
        id: null,
        roles: [],
      },
    };

    store.commit('set', ['auth', auth]);

    localStorage.removeItem('accessToken');
  }
}
