import {logoFull, logoMinimized} from './logo';
import {
  cilAccountLogout,
  cilCart,
  cilCloudDownload,
  cilFrown,
  cilHome,
  cilInstitution,
  cilLightbulb,
  cilLineSpacing,
  cilLockLocked,
  cilLockUnlocked,
  cilNotes,
  cilPaperPlane,
  cilPencil,
  cilSettings,
  cilHouse,
  cilSpreadsheet,
  cilTrash,
  cilUser,
  cilPowerStandby,
  cilX,
  cilGraph,
  cilWarning,
  cilCheck,
  cilReport,
  cilMediaPause,
  cilExternalLink,
  cilTruck,
  cilOptions,
  cilExpandDown,
  cilExpandUp
} from '@coreui/icons';


export default {
  ...{logoFull, logoMinimized},
  ...{
   cilAccountLogout,
   cilCart,
   cilCloudDownload,
   cilFrown,
   cilHome,
   cilInstitution,
   cilLightbulb,
   cilLineSpacing,
   cilLockLocked,
   cilLockUnlocked,
   cilNotes,
   cilPaperPlane,
   cilPencil,
   cilSettings,
   cilSpreadsheet,
   cilTrash,
   cilUser,
   cilX,
   cilHouse,
   cilPowerStandby,
   cilGraph,
   cilWarning,
   cilCheck,
   cilReport,
   cilMediaPause,
   cilExternalLink,
   cilTruck,
   cilOptions,
   cilExpandDown,
   cilExpandUp
 },

}
