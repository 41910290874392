import {camelizeKeys, decamelizeKeys} from 'humps'

export function transformRequest(config) {
  if (config.data instanceof FormData) {
    return config
  }
  if (config.data) {
    config.data = decamelizeKeys(config.data)
  }
  if (config.params) {
    config.params = decamelizeKeys(config.params)
  }
  return config
}

export function camelizeResponse(response) {
  let { data } = response
  if (data instanceof Blob) {
    return response
  }
  data = camelizeKeys(data)
  if (!data) {
    return response
  }
  if (data.data) {
    response.data = data.data
    return response
  }
  if (data.error) {
    response.data = data.error
    return response
  }
  response.data = data
  return response
}
