import './plugins/axios';
import Vue from 'vue';
import CoreuiVue from '@coreui/vue';
import Multiselect from 'vue-multiselect';
import i18n from './i18n';
import App from './App.vue';
import icons from '@/assets/icons';
import router from './router';
import store from './store';

Vue.use(CoreuiVue);

Vue.component('VueMultiselect', Multiselect);

new Vue({
  i18n,
  icons,
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');
